import CentralReview from "./CentralReviewReducer"
import NotificationsReducer from "./notificationReducer"
import ProjectListReducer from "./ProjectListReducer"
import activityTableReducer from "./activityTable"
import collaboratorsReducer from "./collaboratorsReducer"
import { combineReducers } from "redux"
import currentReducer from "./currentReducer"
import projectConfigReducer from "./projectConfigReducer"
import sessionReducer from "./sessionReducer"

export const rootReducer = () =>
  combineReducers({
    session: sessionReducer,
    current: currentReducer,
    project_config: projectConfigReducer,
    collaborators: collaboratorsReducer,
    projects: ProjectListReducer,
    activityTable: activityTableReducer,
    notifications: NotificationsReducer,
    CentralReview,
  })

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
