import * as React from "react"

import { Route, Switch } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import Activity from "./activity-page"
import AdjudicatorViewer from "./adjudicator-viewer"
import CentralReviewProgressMonitoring from "./central-review-progress-monitoring"
import Collaborators from "./collaborators-page"
import CollaboratorsAdd from "../../components/forms/add-edit-collaborator/collaborators-add"
import CreateEditStudy from "../../components/forms/create-edit-study"
import { Grid } from "@material-ui/core"
import MuiBreadCrumbs from "../../components/common/breadcrumbsContext/materialBreadcrumb"
import NotFoundPage from "../../components/common/notFound"
import Overview from "./overview-page"
import ProjectSidePanel from "../../components/common/sidepanel/ProjectSidePanel"
import WorkList from "./work-list-page"
import WorkListMatrix from "./work-list-session-matrix"
import { fetchItem } from "../../actions/apiAction"
import { useEffect } from "react"

const StudyContainer = (props: any) => {
  const dispatch = useDispatch()
  const state = useSelector((state: any) => state)

  useEffect(() => {
    dispatch(
      fetchItem(
        props.match.params.project_id,
        state.session.user_data.username,
        props.history
      )
    )
  }, [props.match.params.project_id, state.session.user_data])

  return (
    <Grid container>
      <Grid item xs={2} sm={2} md={2} lg={2}>
        <ProjectSidePanel {...props} />
      </Grid>
      <Grid item xs={10} sm={10} md={10} lg={10}>
        <MuiBreadCrumbs />
        <Switch>
          <Route
            path={"/project/:project_id/monitoring"}
            render={(props) => <CentralReviewProgressMonitoring {...props} />}
          />
          <Route
            path={"/project/:project_id/worklist"}
            render={(props) => <WorkList {...props} />}
          />
          <Route
            path={"/project/:project_id/activity"}
            render={() => <Activity {...props} />}
          />
          <Route
            path={"/project/:project_id/overview"}
            render={(props) => <Overview {...props} />}
          />
          <Route
            path={"/project/:project_id/task/:task_id/history"}
            render={(props) => <WorkListMatrix {...props} />}
          />
          <Route
            path={"/project/:project_id/adjudicate/:task_id"}
            render={(props) => <AdjudicatorViewer {...props} />}
          />
          <Route
            path={"/project/:project_id/collaborators/add"}
            render={(props) => <CollaboratorsAdd {...props} />}
          />
          <Route
            path={"/project/:project_id/collaborators"}
            render={(props) => <Collaborators {...props} />}
          />
          <Route
            path={"/project/:project_id/information/edit"}
            render={(props) => (
              <CreateEditStudy
                mode={"edit"}
                title={"Edit study information"}
                {...props}
              />
            )}
          />
          <Route component={NotFoundPage} />
        </Switch>
      </Grid>
    </Grid>
  )
}

export default StudyContainer
